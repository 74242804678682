<template>
  <div class="card-assinatura-documentos">
    <div class="fadeInFast card-externo-sign">
      <div class="text-doc msg">Olá, investidor!
      <br>
      <br>A aquisição da sua cota está quase concluída. Para prosseguir, assine os documentos enviados para seu e-mail, ou clique nos botões "Assinar" abaixo para assiná-los.
      <br>Após a assinatura de todos os documentos, poderá prosseguir para o pagamento.
      </div>      
      <div class="line-document">
        <b-col>
          <div class="text-doc">Promessa de Compra e Venda de Cotas</div>          
        </b-col>
        <b-col class="col-button">
          <b-button
                :class="promessa.signed ? 'button-signature button-assinado disabled': 'button-signature'"                
                variant="primary"
                target="_blank"
                :href="promessa.url">
                {{promessa.signed ? 'Assinado' : 'Assinar'}}
          </b-button>
        </b-col>
      </div>
      <div class="line-document">
        <b-col>
          <div class="text-doc">Estatuto de Multipropriedade</div>          
        </b-col>
        <b-col class="col-button">
          <b-button 
                :class="estatuto.signed ? 'button-signature button-assinado disabled': 'button-signature'"
                variant="primary"
                target="_blank"
                :href="estatuto.url">
                {{estatuto.signed ? 'Assinado' : 'Assinar'}}
          </b-button>
        </b-col>
      </div>
      <div v-if="showRegressive" style="text-align: center;">
        <div class="count-signature">Verificaremos se seus documentos foram assinados em {{count}} segundos</div>
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BFormInput, BCol, BSpinner } from 'bootstrap-vue'
import BrokerService from '../../services/BrokerService'
import LoaderVue from '@/components/Loader.vue'
import ClicksignInvestmentService from '../../services/Clicksign/ClicksignInvestmentService'

export default {
  components: {
    BButton,
    BCol,
    BFormInput,
    LoaderVue,
    BSpinner
  },
  emits:['signed'],
  props: ['investmentId'],

  data() {
    return {
      brokerList: [],
      count: 10,
      showRegressive: false,
      promessa: {signed: false, url: ''},
      estatuto: {signed: false, url: ''},
      corretor: {},
      listFields: [
        { key: 'creci', label: 'CRECI' },
        { key: 'name', label: 'Nome' },
        { key: 'actions', label: '' },
      ],
    }
  },

  methods: {
  
    clickAssinarPromessa() {
      this.$router.push(this.promessa.url)
    },
    
    clickAssinarEstatuto() {
      this.$router.push(this.estatuto.url)
    },

    regressiveCount(){
      setTimeout(() => {
        this.count -= 1
        if(this.count === 0) {
          this.count = 10
          this.timeoutInitial()
          return
        }
        this.regressiveCount()
      }, 1000)
    },

    async getDocumentStatus() {
      this.estatuto = (await ClicksignInvestmentService.getEstatuto(this.investmentId)).data.result
      this.promessa = (await ClicksignInvestmentService.getPromessa(this.investmentId)).data.result
      if(this.estatuto.signed && this.promessa.signed) {
        this.$emit('signed')
      }
    },

    async validateStatus(){
      if(this.estatuto.signed && this.promessa.signed) {
        this.showRegressive = false
      } else {
        this.showRegressive = true
      }
    },

    async timeoutInitial() {
      this.showRegressive = false
      await this.getDocumentStatus()
      setTimeout(() => {
        this.validateStatus()
        this.regressiveCount()
      }, 5000)    
    }
  },

  mounted() {
    this.getDocumentStatus()
    this.timeoutInitial()
  },
}
</script>
<style>
.card-externo-sign{
  border: 1px solid #D3D8E3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 3%;
  background: white;    
  height: 100%;
}
.msg {
  font-size: 1.5em;
  font-family: Roboto;
  text-align: left !important;
  padding-top: 1% !important;
  margin-bottom: 15%;
  line-height: inherit;
}
.button-signature{
  width: 50%; 
  min-height: 3em;
  margin-top: 1em; 
  font-size: 1em; 
  margin-bottom: 1em; 
  margin-left: 10%;
}
.button-assinado {
  background: #ffffff !important;
  color: black !important;
  border-color:rgb(148, 148, 148) !important;
  
}
.col-button {
  max-width: 40%;
}
h2 {
  margin-top: 2%;
  margin-bottom: 20%;
}
.line-document {
  display: flex;    
  justify-content: center; 
  margin-left: 8%;
}
.text-doc {
  padding-top: 7%;
  text-align: right;
  font-family: Roboto;
  color: #8b8b8b;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}
span.spinner-border{
  z-index: 9999 !important;
  color: #f8ac18;
}
.count-signature {
  font-size: 1.5em;
  margin-top: 15%;
}
.card-assinatura-documentos{
  width: 100em;
  z-index: 4;
  margin-top: 2.7%;
}
@media (max-width: 1000px) {
  .card-assinatura-documentos{
    width: 100%;
  }
  .line-document{
    display: grid;
    justify-content: initial;
    margin-left: 0 !important;
  }
  .text-doc {
    text-align: center;
  }
  .col-button {
    max-width: 100%;
  }
  .button-signature {
    margin-left: 25%;
  }
}
</style>