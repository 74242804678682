import Vue from 'vue'

const ValidationCpfCnpjService = {

  validaCpf(value) {
    return Vue.prototype.$http.post('/api/services/app/ValidationCpfCnpj/ValidaCpf?cpf='+ value)
  },
  validaCnpj(value) {
    return Vue.prototype.$http.post('/api/services/app/ValidationCpfCnpj/ValidaCnpj?cnpj='+ value)
  },
}

export default ValidationCpfCnpjService
