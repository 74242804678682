<template>
  <div class="div-principal-pagamento">
  <div class="div-secundaria-pagamento">
      <div class="card-pagameto" style="width: 100%">
        <div>
          <b style="font-size: 2em">Forma de Pagamento</b>
        </div>
        <br>
          <ul>
            <li>O pagamento não porderá ser parcelado, a cota deve ser paga integralmente em uma única vez neste momento</li>
          </ul>
        <div class="botoes-pagamentos">
          <b-button
            v-if="(!isPagarBoleto && !isPagarTed) || isPagarBoleto"
            style=" margin-top: 1em;"
            class="btn btn-primary ml-12 float-center button-principal"
            variant="primary"
            @click="pagarBoleto">
            Boleto
          </b-button>
          <b-button
            v-if="isPagarTed && !hasPayment"
            style=" margin-top: 1em;"
            class="btn ml-12 float-center disabled button-alternativo"
            variant="primary"
            @click="pagarBoleto">
              Boleto
          </b-button>
          <b-button
            v-if="(!isPagarBoleto && !isPagarTed) || isPagarTed"
            style=" margin-top: 1em;"
            class="btn btn-primary ml-12 float-center button-principal"
            variant="primary"
            @click="pagarTed">
              Transferência Bancária/TED
          </b-button>
          <b-button
            v-if="isPagarBoleto && !hasPayment"
            style=" margin-top: 1em;"
            class="btn ml-12 float-center disabled button-alternativo"
            variant="primary"
            @click="pagarTed">
              Transferência Bancária/TED
          </b-button>
        </div>
        <div>
          <PaymentBoleto v-if="isPagarBoleto"/>
        </div>
        <div>
          <PaymentTed v-if="isPagarTed"/>
        </div>
        <br>
      </div>
    </div>
    <HtmlToPdf ref="html2Pdf" :html="htmlBoleto"/>
  </div>
</template>
<script>

import { BCard, BButton } from 'bootstrap-vue'
import PaymentService from '../../../services/Investment/PaymentService'
import PaymentBoleto from './PaymentBoleto.vue'
import PaymentTed from './PaymentTed.vue'

export default {
  components: {
    BCard,
    BButton,
    PaymentBoleto,
    PaymentTed,
  },
  data() {
    return {
      isPagarBoleto: false,
      isPagarTed: false,
      loading: false,
      hasPayment: false,
      htmlBoleto: null,
    }
  },

  props: ['investmentId'],
  emits: ['paymentFinished', 'paymentFound', 'back'],

  methods: {
    async pagarBoleto() {
      this.isPagarBoleto = true
      this.isPagarTed = false
      this.$emit('paymentFinished', 'boleto')
    },

    async pagarTed() {
      this.isPagarBoleto = false
      this.isPagarTed = true
      this.$emit('paymentFinished', 'ted')
    },

    validate() {
    },

    printBoleto() {
      // const { result } = (await PaymentService.getQuotaPaymentByInvestment(this.investmentId)).data
    },

    back(){
      this.$emit('back')
    },

    async getPayment() {
      const { result } = (await PaymentService.getQuotaPaymentByInvestment(this.investmentId)).data
      if (result && result.quotaPayment) {
        this.hasPayment = true
        if (result.quotaPayment.type === 1) {
          this.pagarBoleto()
        } else {
          this.pagarTed()
        }
        this.$emit('paymentFound')
      }
    },
  },

  mounted() {
    if (this.investmentId) {
      this.getPayment()
    }
  },
}
</script>
<style scoped>
.button-alternativo {
  background-color: #ffffff !important;
  color: #676d7d !important;
  border-color: #b4b4b4 !important;
  width: 40%; 
  min-height: 3em; 
  font-size: 1.5em;
  margin-left: 1.5em;
}

.button-principal {
  background-color: #676d7d !important;
  border: 0px !important;
  box-shadow: 0 0 0 0 #ffffff !important;
  width: 40%; 
  min-height: 3em; 
  font-size: 1.5em;
  margin-left: 1.5em;
}
.card-pagameto {  
    border: 1px solid #D3D8E3;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 3%;
    background: white;    
}
[dir] .btn-primary:hover:not(.disabled):not(:disabled){
  box-shadow: 0 0px 0px 0px var(--color) !important;
}
.div-principal-pagamento{
  width: 100em;
  z-index: 2;
  margin-top: 2.7%;
}
.div-secundaria-pagamento{
  margin-left: 2em;
  display: relative;
}
.botoes-pagamentos{
  display: flex;
}
@media (max-width: 1000px) {
  .div-principal-pagamento{
    width: 100% !important;
  }
  .div-secundaria-pagamento{
    margin-left: 0 !important;
  }
  .botoes-pagamentos{
    display: grid;
  }
  .button-principal{
    width: 100% !important;
    margin-left: 0 !important;
  }
  .button-alternativo{
    width: 100% !important;
    margin-left: 0 !important;
  }
}
</style>