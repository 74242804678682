<template>
    <div style="display: flex; height: 45em;">
      <b-card style="width: 100%; height: 100%">
        <div>Informe quem fez seu atendimento</div>
        <br>
        <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              type="search"
              placeholder="Pesquisar"
              @update="filterBroker">
            </b-form-input>
        </b-input-group>
        <b-table id="table_brokers" class="mt-1" small :fields="listFields" :items="brokerList" responsive="sm">
            <template #cell(actions)="data">
                <div class="float-right">
                    <b-button @click="select(data.item)" size="sm" variant="primary">Selecionar</b-button>
                </div>
            </template>
        </b-table>
      <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Mostrando </span>
                <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                    value => props.perPageChanged({ currentPerPage: value })
                " />
                <span class="text-nowrap"> de {{ props.total }} Resultados </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      </b-card>
      <b-modal id="modal-corretor-confirm" @ok="modalCorretorConfirm">Confirma o atendimento pelo corretor <b>{{corretor.name}}</b> - CRECI <b>{{corretor.creci}}</b></b-modal>
    </div>
</template>

<script>
import { BCard, BButton, BTable, BInputGroup, BFormInput, BPagination, BFormSelect } from 'bootstrap-vue'
import BrokerService from '../../services/BrokerService'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BInputGroup,
    BFormInput,
    BPagination,
    BFormSelect,
  },
  emits: ['selected'],

  data() {
    return {
      brokerList: [],
      corretor: {},
      listFields: [
        { key: 'creci', label: 'CRECI' },
        { key: 'name', label: 'Nome' },
        { key: 'actions', label: '' },
      ],
    }
  },

  methods: {
    select($event) {
      this.corretor = $event
      this.$bvModal.show('modal-corretor-confirm')
    },

    modalCorretorConfirm() {
      this.$emit('selected', this.corretor)
    },

    async filterBroker($event) {
      this.brokerList = (await BrokerService.getAllToInvestmentAssociation($event)).data.result
    },
  },

  mounted() {
    this.filterBroker('')
  },
}
</script>
<style>


</style>