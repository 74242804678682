<template>
  <div>
    <Loader v-if="loading" :text="textLoading"/>
    <div v-if="!showFinish" style="background: white !important">
      <br />
      <div style="z-index: 1 !important; width: 100%">
        <b-icon style="margin-left: 2em" class="color-icon" icon="chevron-left" /> <a style="margin-left: 0.5em" @click="goToList"> Voltar para lista</a>
        <b-breadcrumb class="breadcrumb-custom" ref="breadcrumb" :items="items">
          <b-breadcrumb-item v-for="item in items" :id="item.ref" :ref="item.ref" :key="item.ref" :active="item.active" :to="item.to" :icon="'HomeIcon'">
            {{ item.text }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <div v-if="item" class="card-principal">
        <PersonalData ref="personal_data" v-if="!getItem('dados_pessoais').active" />
        <Procedures ref="procedures" class="fadeInFast" v-if="!getItem('tramites').active && !showClicksign" @acceptedAll="acceptedAll" @back="back" />
        <SignatureDocuments ref="signature" v-if=" !getItem('tramites').active && showClicksign" :investmentId="investmentId" @signed="signed" />
        <Payment
          v-if="!getItem('pagamento').active"
          ref="payment"
          class="fadeInFast"
          :investmentId="investmentId"
          @paymentFound="paymentFound"
          @paymentFinished="paymentFinished"
          @back="backPayment" />
        <div class="card-resume">
          <div class="div-secundaria">
            <div class="label-resumo">Resumo do Pedido</div>
            <br />
            <div>
              <div class="dados-imovel">
                <b-img class="imgt" thumbnail fluid :src="`${item.image.dataType},${item.image.bytes}`" alt="image 1" />
                <div>
                  <div class="nome-imovel">{{ item.name }}</div>
                  <div class="total-disponivel">
                    <div>Total de cotas disponiveis:</div>
                    <div style="color: #b8c2cc">{{ item.qtdAvailable }}</div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <hr class="line" style="margin-top: 3%" />
            <div>
              <div class="label-qtd-desejada">Quantas cotas você deseja comprar:</div>
              <b-col sm="12" style="padding-right: 0; padding-left: 0">
                <b-form-input
                  class="input-qtd-cotas"
                  type="number"
                  min="0"
                  id="nome-imovel"
                  style="text-align: end; font-size: 1.5em"
                  v-model="form.qtd"
                  @update="calcularTotal"
                  :disabled="!getItem('pagamento').active || showClicksign" />
              </b-col>
            </div>
            <div style="display: flex">
              <div class="label-valor-cota">Valor Por cota:</div>
              <div class="valor-cota">{{ formatCurrencyPrice(item.quotaValue) }}</div>
            </div>
            <hr class="line" style="margin-top: 5%" />
            <div style="display: flex">
              <div class="label-total">Total:</div>
              <div class="valor-total">{{ formatCurrencyPrice(total) }}</div>
            </div>
            <div v-if="!getItem('pagamento').active">
              <hr class="line" style="margin-top: 2%" />
              <div class="linha-informar-corretor">
                <div>Foi atendido por um corretor?</div>
                <div>Informe quem fez seu atendimento</div>
                <b-button class="button-corretor ml-12 button-disabled disabled" v-ripple.400="'rgba(255, 255, 255, 0.5)'" variant="primary" @click="$bvModal.show('modal-corretor-select')">
                  {{ corretor === null ? 'Informar Corretor' : `Corretor: ${corretor.name}` }}
                </b-button>
                <b-button
                  v-if="!disableNext && !hasPayment"
                  v-ripple.400="'rgba(255, 255, 255, 0.5)'"
                  class="button-avancar btn btn-primary ml-12"
                  variant="primary"
                  @click="validateAndNext()">
                  Avançar
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.5)'"
                  class="button-avancar-disabled ml-12 button-disabled disabled"
                  variant="primary"
                  disabled>
                  Avançar
                </b-button>
              </div>
            </div>
            <b-button
              v-if="!disableNext && getItem('pagamento').active"
              style="width: 96%; min-height: 3em; font-size: 1.5em; margin-top: 1.5em; margin-bottom: 1em; background-color: #f8ac18 !important"
              v-ripple.400="'rgba(255, 255, 255, 0.5)'"
              class="btn btn-primary ml-12"
              variant="primary"
              @click="validateAndNext()">
              Avançar
            </b-button>
            <b-button
              v-else-if="getItem('pagamento').active"
              style="width: 96%; min-height: 3em; font-size: 1.5em; margin-top: 1.5em; margin-bottom: 1em"
              v-ripple.400="'rgba(255, 255, 255, 0.5)'"
              class="ml-12 button-disabled disabled"
              variant="primary"
              disabled>
              Avançar 
            </b-button>
          </div>
        </div>
      </div>
      <b-modal id="modal-corretor-alert" centered class="modal-alert-corretor" @ok="modalCorretorAlertOk">Você não informou um corretor. Deseja continuar?</b-modal>
      <b-modal class="modal-personal" size="lg" id="modal-corretor-select" hide-footer>
        <div class="d-block text-center">
          <BrokerAssociated @selected="corretorSelected"></BrokerAssociated>
        </div>
      </b-modal>
    </div>
    <div v-else>
      <div class="box-finished" style="background: #f8ac18">
        <b-img class="icon-finished" src="@/assets/images/pages/confirmation-investment.png" />
        <div class="text-finished-title">Pedido realizado com sucesso!</div>
        <div class="text-finished-msg">
          Ficamos muito felizes com seu investimento, no prazo máximo de 72h você receberá um retorno via e-mail com as demais informações do seu pedido e o mesmo ficará disponível na sua carteira
          para acompanhamento.
        </div>
        <a style="margin-bottom: 30%">
          <div @click="goToDashboard" class="finished-button">
            <b-icon icon="check" aria-hidden="true"></b-icon>
          </div>
        </a>
      </div>
    </div>
    <HtmlToPdf ref="html2Pdf" :html="htmlBoleto" />
  </div>
</template>
<script>
import toast from '@/mixins/toast'
import { BImg, BFormInput, BBreadcrumb, BBreadcrumbItem, BCol, BButton, BModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Loader from '../../components/Loader.vue'
import HtmlToPdf from '../../components/HtmlToPdf.vue'
import PaymentService from '../../services/Investment/PaymentService'
import OpportunityService from '../../views/site/services/OpportunityService'
import PersonalData from './PersonalData.vue'
import Procedures from './Procedures.vue'
import SignatureDocuments from './SignatureDocuments.vue'
import Payment from './payment/Payment.vue'
import InvestmentService from '../../services/Investment/InvestmentService'
import BrokerInvestmentService from '../../services/Investment/BrokerInvestmentService'
import InvestmentSituationEnum from '../../enums/InvestmentSituationEnum'
import BrokerAssociated from './BrokerAssociated.vue'
import useJwt from '../../auth/jwt/useJwt'
import formatter from '@/mixins/formatter'

export default {
  components: {
    BButton,
    Loader,
    HtmlToPdf,
    BImg,
    BCol,
    BFormInput,
    BModal,
    PersonalData,
    BBreadcrumb,
    Procedures,
    BrokerAssociated,
    BBreadcrumbItem,
    Payment,
    SignatureDocuments,
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  directives: {
    Ripple,
  },
  mixins: [toast, formatter],
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },

  data() {
    return {
      htmlBoleto: null,
      immobileId: null,
      investmentId: null,
      investment: {},
      loading: false,
      total: 0,
      atual: 1,
      disableNext: false,
      isBoleto: false,
      hasPayment: false,
      paymentSelected: false,
      showFinish: false,
      showClicksign: false,
      isSigned: false,
      item: null,
      userData: null,
      form: {},
      corretor: null,
      naoInformarCorretor: false,
      textLoading: '',
      items: [
        {
          text: 'Perfil do Investidor',
          ref: 'perfil_inv',
          active: true,
        },
        {
          text: 'Revisar Dados Pessoais',
          ref: 'dados_pessoais',
          active: false,
        },
        {
          text: 'Tramites Legais',
          ref: 'tramites',
          active: true,
        },
        {
          text: 'Pagamento',
          ref: 'pagamento',
          active: true,
        },
      ],
    }
  },

  methods: {
    goToDashboard() {
      this.$router.push('/dashboard')
    },
    goToList() {
      if(this.investmentId) {
        this.$router.go(-1)
      } else {
        this.$router.push('/opportunity')
      }
    },

    getItem(ref) {
      const item = this.items.find(i => i.ref === ref)
      return item
    },

    calcularTotal() {
      this.total = Number(this.form.qtd) * this.item.quotaValue
      if (Number(this.form.qtd) < 0) {
        //console.log(this.form.qtd)
        this.form.qtd = 0
        this.total = 0
      }
    },

    async validateAndNext() {
      if (this.hasPayment) {
        return
      }
      if (!this.getItem('dados_pessoais').active) {
        if (!this.$refs.personal_data.validate()) {
          return
        }
      }

      if (!this.getItem('tramites').active && !this.showClicksign) {
        this.loading = true
        await this.createInvestment()
        this.loading = false
          this.scrollTop()
        return
      }

      if (!this.getItem('pagamento').active) {
        try {
          if (this.corretor === null && !this.naoInformarCorretor) {
            this.$bvModal.show('modal-corretor-alert')
            return
          }
          this.loading = true
          await this.createPayment()
          this.loading = false
        } catch (ex) {
          this.loading = false
          return
        }
      }
      if (!this.validacoesResumo()) {
        return
      }

      this.next()
      if ((!this.getItem('tramites').active || !this.getItem('pagamento').active) && (this.isSigned === false || this.paymentSelected === false)) {
        this.disableNext = true
      }     
      this.scrollTop() 
    },

    scrollTop(){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    validacoesResumo() {
      if (!this.getItem('pagamento').active) {
        return true
      }
      const { qtd } = this.form
      if (qtd === undefined || Number(qtd) <= 0) {
        this.msgError('Informe a quantidade de cotas')
        return false
      }
      if (Number(qtd) > this.item.qtdAvailable) {
        this.msgError('A quantidade de cotas desejada é superior a quantidade de cotas disponíveis')
        return false
      }
      return true
    },

    async createPayment() {
      if (this.hasPayment) {
        return
      }
      if (this.isBoleto) {
        this.loading = true
        this.htmlBoleto = (await PaymentService.createBoletoPayment(this.investmentId)).data.result
        this.$refs.html2Pdf.generatePdf()
        this.showFinish = true
        this.loading = false
        localStorage.removeItem('immobileIdSelected')
      } else {
        PaymentService.createTedPayment(this.investmentId)
        this.showFinish = true
      }
    },

    async paymentFinished($event) {
      this.disableNext = false
      this.paymentSelected = true
      this.isBoleto = $event === 'boleto'
    },

    paymentFound() {
      this.hasPayment = true
      this.paymentSelected = true
      this.disableNext = false
    },

    modalCorretorAlertOk() {
      this.naoInformarCorretor = true
      this.validateAndNext()
    },

    async createInvestment() {
      this.form.immobileId = this.immobileId
      this.loading = true
      this.textLoading = 'Por favor aguarde, estamos preparando seus documentos'
      this.form.brokerUserId = localStorage.getItem('brokerUserId')
      await InvestmentService.CreateOrEdit(this.form)
        .then(res => {
          this.investmentId = res.data.result
          this.showClicksign = true
          this.disableNext = true
          this.textLoading = ''
        })
        .catch(error => {
          this.loading = false
          this.msgError('Prezado cliente, houve uma falha na comunicação com a plataforma de assinatura eletrônica. Por favor, tente novamente mais tarde.')
          throw new Error(error.response.data.error.message)
        })
        await this.getCorretor()
    },

    next() {
      this.atual += 1
      const item = this.items[this.atual]
      if (item) {
        const { length } = this.items
        item.active = false
        for (let i = 0; i <= length; i += 1) {
          if (i !== this.atual && i < length) {
            this.items[i].active = true
          }
        }
      }
    },

    back() {
      this.atual = 1
      this.getItem('dados_pessoais').active = false
      this.getItem('tramites').active = true
      this.getItem('pagamento').active = true
      this.disableNext = false
    },
    backPayment() {
      this.atual = 2
      this.getItem('dados_pessoais').active = true
      this.getItem('tramites').active = false
      this.getItem('pagamento').active = true
      this.disableNext = true
    },

    async corretorSelected($event) {
      this.corretor = $event
      this.$bvModal.hide('modal-corretor-select')
      await BrokerInvestmentService.createOrEdit({ userBrokerProfileId: this.corretor.id, investmentQuotaId: this.investmentId })
    },

    acceptedAll($event) {
      this.disableNext = !$event
    },

    async getCorretor() {
      const { result } = (await BrokerInvestmentService.getAssociatedBrokerByInvestment(this.investmentId)).data
      if (result.investmentAssociatedBroker) {
        this.corretor = { name: result.userBrokerProfileName }
      }
    },

    async signed(){
      await InvestmentService.updateSituationToPaymentPendent(this.investmentId)
      this.disableNext = false
      this.isSigned = true
      this.atual = 2
    },

    async getInvestment(){
      const investiment = (await InvestmentService.getInvestmentQuota(this.investmentId)).data.result.investmentQuota
      this.form.qtd = investiment.qtd
      this.calcularTotal()
      this.getCorretor()

      if(investiment.situation === InvestmentSituationEnum.PENDENTE_ASSINATURA) {        
        this.disableNext = true
        this.getItem('dados_pessoais').active = true
        this.getItem('tramites').active = false
        this.getItem('pagamento').active = true
        this.showClicksign = true
      }
      
      if(investiment.situation === InvestmentSituationEnum.PENDENTE_PAGAMENTO) {        
        this.getItem('dados_pessoais').active = true
        this.getItem('tramites').active = true
        this.getItem('pagamento').active = false
        if(this.hasPayment === false) {
          this.disableNext = true
        }
      }
    },
  },

  async mounted() {
    this.immobileId = localStorage.getItem('immobileIdSelected')
    if (this.immobileId === null) {
      this.$router.push('/dashboard')
      return
    }
    const token = localStorage.getItem('accessToken')
    useJwt.setToken(token)
    this.loading = true
    const result = await OpportunityService.getResume(this.immobileId)
    this.item = result.data.result
    this.loading = false
    this.atual = 1
    this.investmentId = this.$route.params['id']
    if (this.investmentId) {
      this.getInvestment()
    }
  },
}
</script>
<style lang="scss">
.button-disabled {
  font-size: 1.5em;
  background-color: white !important;
  border-color: white !important;
  color: grey !important;
}

.modal-alert-corretor .modal-dialog.modal-md {
  margin-top: 20%;
}
.breadcrumb-custom {
  position: inline-flex !important;
  float: right;
  margin-right: 2%;
}
.card-resume {
  position: relative;
  width: 95%;
  height: 60%;
  margin-top: 2.7%;
  margin-left: 2%;
  margin-right: 2%;
  padding-left: 2%;
  padding-top: 2%;
  background: white;
  box-shadow: 0px 4px 24px 4px rgba(211, 216, 227, 0.1);
  border-radius: 10px;
  z-index: 4 !important;

  border: 1px solid #d3d8e3;
  box-sizing: border-box;
  border-radius: 10px;
}
.label-resumo {
  position: relative;
  width: 50%;
  height: 21px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 21px;
  color: #555c69;
}
.imgt {
  position: relative;
  border-radius: 1em;
  border: 0;
}
.nome-imovel {
  position: relative;
  top: 0.1em;
  margin-left: 0.5em;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 200%;
  line-height: 40px;
}
.total-disponivel {
  position: relative;
  width: 100%;
  height: 50px;
  margin-left: 0.8em;
  top: 1.2em;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 130%;
  line-height: 20px;
}

.label-qtd-desejada {
  position: relative;
  width: 100%;
  height: 19px;
  margin-top: 3%;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 130%;
  line-height: 23px;
}
.input-qtd-cotas {
  position: relative;
  width: 96%;
  height: 2.5em;
  margin-top: 3%;
}
.label-valor-cota {
  position: relative;
  width: 30%;
  height: 17px;
  margin-top: 3%;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #555c69;
}
.valor-cota {
  position: relative;
  width: 70%;
  height: 25px;
  right: 4%;
  margin-top: 3%;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: #a6adba;
}
.label-total {
  position: relative;
  width: 30%;
  height: 18px;
  margin-top: 2%;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #555c69;
}
.valor-total {
  position: relative;
  width: 70%;
  height: 25px;
  right: 4%;
  margin-top: 2%;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: #7c8391;
}
hr {
  position: relative;
  width: 96%;
  height: 0px;
  right: 2%;
}
.row.content-header {
  display: none;
}

.box-finished {
  background: #f8ac18;
  width: 60em;
  height: 50em;
  border-radius: 5%;
  margin-left: auto;
  margin-right: auto;
}

.finished-button {
  width: 40%;
  height: 3.3em;
  background: black;
  border-radius: 6px;
  margin-left: 32%;
  margin-top: 2%;
}

.icon-finished {
  width: 40%;
  margin-left: 32%;
  margin-top: 10%;
}

[dir='ltr'] .bi-check.b-icon.bi {
  margin-left: 38%;
}

.text-finished-title {
  font-size: 32px;
  line-height: 40px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: white;
  margin-left: 2%;
}
.text-finished-msg {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: white;
  margin-top: 2%;
  margin-left: 2%;
  padding-left: 25%;
  padding-right: 25%;
}
.button-corretor {
  width: 40% !important;
  min-height: 3em !important;
  font-size: 1.5em !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  border-color: grey !important;
}
.card-principal{
  display: flex; 
  background: white;
  width: 100%;
}
.dados-imovel{
  display: flex;
}
.button-avancar {
  width: 40%;
  min-height: 3em;
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 10%;
  background-color: #f8ac18 !important;
}
.button-avancar-disabled {
  width: 40%;
  min-height: 3em;
  margin-top: 1em;
  font-size: 1.5em;
  margin-left: 10%;
  margin-bottom: 1em;
}

@media (max-width: 1000px) {
  .card-resume {
    width: 100%;    
    margin-left: 0%;
    padding: 3%;
  }
  .div-secundaria{
    margin-left: 2%;
  }
  .card-principal {
    display: flow-root;
    padding: 3%;
  }
  .dados-imovel{
    display: flow-root;
  }
  .imgt {
    width: 98%;
    height: auto;
  }
  .linha-informar-corretor {
    display: grid;
  }
  .button-corretor {
    margin-top: 0 !important;
    margin-bottom: 0.5em !important;
    width: 98% !important;
  }
  .button-avancar {
    width: 100% !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
  }
  .button-avancar-disabled {
      width: 100% !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
  }
  .nome-imovel {
    margin-left: 0.5%;
  }
  .total-disponivel {
    margin-left: 0.5%;
  }
  .label-valor-cota{
    width: 100%;
  }
  .box-finished {
    width: 100% !important;
  }
  .text-finished-msg{
    margin-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .finished-button{
    width: 80%;
    margin-left: 10%;
    margin-top: 10%;
  }
}

</style>
