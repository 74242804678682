<template>
  <div class="card-procedures">
    <Loader v-if="loading"/>
    <div class="fadeInFast card-externo">
      <div id="card-termo" class="card-termo" style="width: 100%; height: 100%;">
        <div v-if="termsVisible">
          <text-editor style="border: 0" ref="data_content" :text="text" :disable="1"/>
        </div>
        <br>
        <div class="row-buttons-procedures">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.5)'"
            class="botao-voltar-procedures ml-12 float-left button-disabled disabled"
            variant="primary"
            @click="back">
            Voltar
          </b-button>
          <b-button 
            v-if="!acceptedAll"
            v-ripple.400="'rgba(255, 255, 255, 0.5)'"
            class="botao-aceitar-procedures btn btn-primary ml-12 float-right"
            variant="primary"
            @click="next">
            Aceitar
          </b-button>
          <div class="accepted-all" v-if="acceptedAll">
            <b-icon icon="check" aria-hidden="true"></b-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BButton, BIcon } from 'bootstrap-vue'
import TextEditor from '../TextEditor.vue'
import TermoService from '../../services/TermoService'
import Loader from '../../components/Loader.vue'

export default {
  components: {
    BCard,
    BIcon,
    BButton,
    TextEditor,
    Loader,
  },
  emits: ['acceptedAll', 'back'],

  data() {
    return {
      acceptedAll: false,
      termsVisible: true,
      orderServiceVisible: false,
      privacityVisible: false,
      text: null,
      loading: false,
      editorSettings: {
        modules: {
          toolbar: {
              container: [[{'size': ['small', false, 'large']}],]
          }
        }
      }
    }
  },

  methods: {
    next() {
      this.acceptedAll = true
      this.$emit('acceptedAll', true)
    },
    back() {
      this.acceptedAll = false
      this.$emit('back', false)
    },
  },
  async mounted() {
    this.text = (await TermoService.getTermoForEdit()).data.result.termo.text
  },
}
</script>
<style>
/* width */
#card-termo::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#card-termo::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
#card-termo::-webkit-scrollbar-thumb {
  background: #F8AC18;
  border-radius: 10px;
}

/* Handle on hover */
#card-termo::-webkit-scrollbar-thumb:hover {
  background: #cca14c;
}
.ql-toolbar.ql-snow {
  display: none;
}
.ql-container.ql-snow {
  border: 0;
}
.bi-check.b-icon.bi {
  width: 5em !important;
  height: 3.5em !important;
  margin-left: 36%;
  color: white;
}
.accepted-all {
  float: right; 
  width: 40%; 
  height: 3.3em; 
  background: black; 
  border-radius: 6px;
}
.text-editor-termo {
  position: absolute;
  width: 589px;
  height: 549px;
  left: 321px;
  top: 283px;
}
.ql-editor  {
  max-height: 36em;
}
.card-termo {  
  border: 1px solid #D3D8E3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 3%;
  background: white;
}
.card-externo{
  display: relative; 
  height: 89.3%;
  margin-left: 2em;
}
.card-procedures {
  width: 100em;
  z-index: 4;
  margin-top: 2.7%;
}
.row-buttons-procedures{
 margin-top: 2%; 
 margin-bottom: 5%
}
.botao-voltar-procedures{
  width: 40%; 
  min-height: 2em; 
  font-size: 1.5em; 
  color: black !important; 
  font-weight: bold;
}
.botao-aceitar-procedures {
  width: 35%;
  min-height: 2em;
  font-size: 1.5em;
}

@media (max-width: 1000px) {
  .card-procedures {
    width: 100%;
  }
  .card-externo{
    margin-left: 0;
  }
  .row-buttons-procedures{
    display: grid;
  }
  .botao-voltar-procedures{
    width: 100% !important; 
  }
  .accepted-all{
    width: 100%;
  }
  .botao-aceitar-procedures {
    width: 100%;
  }
}
</style>
