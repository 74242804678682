<template>
  <b-card>
    <Loader v-if="loading" />
    <validation-observer
      ref="personalRules"
      v-slot="{ handleSubmit }"
      tag="form">
      <b-form @submit.prevent="handleSubmit(validationForm)">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="UserIcon" size="18" />
              <h4 class="mb-0 ml-75">Dados Pessoais</h4>
            </div>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Nome Completo" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required">
                <b-form-input
                  v-model="dataUser.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group v-if="optionsLocal.typePerson === 'F'" label="CPF" label-for="account-cpf">
              <validation-provider
                #default="{ errors }"
                name="CPF"
                :rules="required">
                <b-form-input
                  v-model="optionsLocal.numberCpfCnpj"
                  @change="validarCpf($event)"
                  v-mask="'###.###.###-##'"
                  :state="errors.length > 0 ? false : null"
                  placeholder="" :disabled="disableCpfCnpj"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group v-else label="CNPJ" label-for="account-cpf">
              <validation-provider
                #default="{ errors }"
                name="CNPJ"
                rules="required">
                <b-form-input
                  v-model="optionsLocal.numberCpfCnpj"
                  v-mask="'##.###.###/####-##'"
                  @change="validarCnpj($event)"
                  :state="errors.length > 0 ? false : null"
                  placeholder="" :disabled="disableCpfCnpj"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.typePerson === 'F'" sm="3">
            <b-form-group label="RG" label-for="account-rg">
              <validation-provider
                #default="{ errors }"
                name="rg"
                rules="required">
                <b-form-input
                  v-model="optionsLocal.numberRg"
                  :state="errors.length > 0 ? false : null"
                  placeholder="99.999.999-9" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.typePerson === 'F'"  sm="2">
            <b-form-group label="Orgão Expedidor" label-for="account-orgao">
              <validation-provider
                #default="{ errors }"
                name="orgao"
                rules="required">
                <b-form-input
                  v-model="optionsLocal.expeditedOrgan"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Detran" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.typePerson === 'F'"  sm="3">
            <b-form-group label="UF" label-for="account-uf">
              <validation-provider
                #default="{ errors }"
                name="uf"
                rules="required">
                <v-select
                  id="account-uf"
                  v-model="optionsLocal.ufExpeditedOrgan"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.typePerson === 'F'"  sm="4">
            <b-form-group label="SEXO" label-for="account-sexo">
              <validation-provider
                #default="{ errors }"
                name="sexo"
                rules="required">
                <v-select
                  id="account-sexo"
                  v-model="optionsLocal.sexo"
                  label="title"
                  :options="listaSexo"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.typePerson === 'F'" md="4">
            <b-form-group
              label-for="example-datanasc"
              label="Data de Nascimento">
              <validation-provider
                #default="{ errors }"
                name="birthDate"
                rules="required">
                  <b-form-input
                  v-model="optionsLocal.birthDate"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.typePerson === 'F'" sm="4">
            <b-form-group label="Estado Civíl" label-for="account-ecivil">
              <validation-provider
                #default="{ errors }"
                name="estadocivil"
                rules="required">
                <v-select
                  id="account-ecivil"
                  v-model="optionsLocal.civilState"
                  label="title"
                  :options="estadosCivis"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.typePerson === 'F'" md="4">
            <b-form-group label-for="nacionalidade" label="Nacionalidade">
              <validation-provider
                #default="{ errors }"
                name="datanasc"
                rules="required">
                <v-select
                  id="nacionalidade"
                  v-model="optionsLocal.nationality"
                  label="title"
                  :options="listaNacionalidades"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input
                v-model="optionsLocal.emailAddress"
                name="email"
                placeholder="Email"
                readonly />
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label="Celular" label-for="account-celular">
              <validation-provider
                #default="{ errors }"
                name="celular"
                rules="required">
                <b-form-input
                  v-model="optionsLocal.phoneNumber"
                  v-mask="['(##) #####-####']"
                  :state="errors.length > 0 ? false : null"
                  placeholder="(99) 99999-9999" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group label="Exposição" label-for="vi-nacional">
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="optionsLocal.politicallyExposedPerson"
                name="checkbox-1"
                :reduce="title => title.value">
                Pessoa Políticamente Exposta?
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" />
        </b-row>
        <hr class="my-2">
        <b-row v-if="isInvestor">
          <div v-if="!hasDocuments" class="documentos fadeIn">
            <div>
              <b class="title-card">Documentação </b>
              <b style="float:right;font-size: 1.2em;color: #f8ac18;margin-top: 10px;"/>
            </div>
            <br>

            <div class="upload-documents-modal">
              <div>
                <b-row />
                <b-row class="custon-center">
                  <ImgUpload :id="'uploadDoc'" :value="documentFile[0]" class="upload-doc-card-settings" textCard="Foto do documento (RG, CPF)" @changeFileGetBinary="changeFile($event, documentFile)"/>
                </b-row>
              </div>
              <div>
                <b-row />
                <b-row class="custon-center">
                  <ImgUpload :id="'uploadSelfie'" :value="selfieDocumentFile[0]" class="upload-selfie-card-settings" textCard="Selfie com o documento (RG, CPF)" @changeFileGetBinary="changeFile($event, selfieDocumentFile)"/>
                </b-row>
              </div>
            </div>

          </div>
        </b-row>
        <hr class="my-2">
        <b-row v-if="optionsLocal.typePerson === 'J'">
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="UserIcon" size="18" />
              <h4 class="mb-0 ml-75">Dados Representante</h4>
            </div>
          </b-col>
          <b-col :sm=" isModal ? 12 : 5">
            <b-form-group label="Nome Completo" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="isModal ? 3 : 2">
            <b-form-group
              label-for="example-datanasc"
              label="Data de Nascimento">
              <validation-provider
                #default="{ errors }"
                name="birthDate"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.birthDate"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="isModal ? 3 : 2">
            <b-form-group label="CPF" label-for="account-cpf">
              <validation-provider
                #default="{ errors }"
                name="CPF"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.cpf"
                  v-mask="'###.###.###-##'"
                  :state="errors.length > 0 ? false : null"
                  @change="validarCpfRepresentante($event)"
                  placeholder=""
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :sm="isModal ? 2 : 3">
            <b-form-group label="RG" label-for="account-rg">
              <validation-provider
                #default="{ errors }"
                name="RG"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.rg"
                  :state="errors.length > 0 ? false : null"
                  placeholder="9999999" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :sm="isModal ? 4 : 2">
            <b-form-group label="Estado Civíl" label-for="account-ecivil">
              <validation-provider
                #default="{ errors }"
                name="estadocivil"
                rules="required">
                <v-select
                  id="account-ecivil"
                  v-model="optionsResponsible.civilState"
                  label="title"
                  :options="estadosCivis"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="isModal ? 4 : 3">
            <b-form-group label-for="nacionalidade" label="Nacionalidade">
              <validation-provider
                #default="{ errors }"
                name="Nacionalidade"
                rules="required">
                <v-select
                  id="nacionalidade"
                  v-model="optionsResponsible.nationality"
                  label="title"
                  :options="listaNacionalidades"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Profissão" label-for="profession">
              <validation-provider
                #default="{ errors }"
                name="Profissão"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.profession"
                  :state="errors.length > 0 ? false : null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :sm="isModal ? 4 : 3">
            <b-form-group label="Celular" label-for="account-celular">
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.phoneNumber"
                  v-mask="['(##) #####-####']"
                  :state="errors.length > 0 ? false : null"
                  placeholder="(99) 99999-9999" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>          
          <b-col :sm="isModal ? 6 : 3">
            <b-form-group label="Rua" label-for="street">
              <validation-provider
                #default="{ errors }"
                name="Rua"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.street"
                  :state="errors.length > 0 ? false : null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>    
          <b-col :sm="isModal ? 3 : 2">
            <b-form-group label="Número" label-for="streetNumber">
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.streetNumber"
                  type="number"
                  :state="errors.length > 0 ? false : null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :sm="isModal ? 3 : 2">
            <b-form-group label="CEP" label-for="account-cep">
              <validation-provider
                #default="{ errors }"
                name="cep"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.postalCode"
                  v-mask="['#####-###']"
                  name="cep"
                  :state="errors.length > 0 ? false : null"
                  placeholder="99999-999"
                  @blur="buscaCep()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :sm="isModal ? 4 : 2">
            <b-form-group label="Bairro" label-for="district">
              <validation-provider
                #default="{ errors }"
                name="Bairro"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.district"
                  :state="errors.length > 0 ? false : null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :sm="isModal ? 5 : 3">
            <b-form-group label="Cidade" label-for="city">
              <validation-provider
                #default="{ errors }"
                name="Cidade"
                rules="required">
                <b-form-input
                  v-model="optionsResponsible.city"
                  :state="errors.length > 0 ? false : null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :sm="isModal ? 3 : 2">
            <b-form-group label="UF" label-for="account-uf">
              <validation-provider
                #default="{ errors }"
                name="uf"
                rules="required">
                <v-select
                  id="account-uf"
                  v-model="optionsResponsible.estateId"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="botao-salvar-dados-pessoais mt-2 mr-1">
              Salvar Mudanças
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import ImgUpload from '../img/ImgUpload.vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import Moment from 'moment/moment'
import Loader from '../../components/Loader.vue'
import StateService from '../../services/StateService'
import UserResponsibleService from '../../services/User/UserResponsibleService'
import Validator from 'vee-validate';
import ValidationCpfCnpjService from '@/services/ValidationCpfCnpjService'
import toast from '@/mixins/toast'
import InvestorDocumentsService from '@/services/Investor/InvestorDocumentsService'
import BinaryObjectsService from '@/services/BinaryObjectsService'

export default {
  components: {
    BButton,
    Loader,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImgUpload,
    vSelect,
    flatPickr,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    Moment,
  },
  directives: {
    Ripple,
  },
  emits: [
    'save',
  ],
  mixins:[toast],
  props: ['isModal'],
  data() {
    return {
      loading: false,
      isInvestor: false,
      required,
      documentsId: null,
      documentFile: [],
      selfieDocumentFile: [],
      dataUser: {
        id: localStorage.getItem('id'),
        name: '',
      },
      profileFile: null,      
      listaUf: [],
      disableCpfCnpj: false,
      listaNacionalidades: [
        {
          title: 'Brasileiro Nato',
          value: 1,
        },
        {
          title: 'Brasileiro Naturalizado',
          value: 2,
        },
        {
          title: 'Estrangeiro',
          value: 3,
        },
      ],
      estadosCivis: [
        {
          title: 'Solteiro',
          value: 1,
        },
        {
          title: 'Casado',
          value: 2,
        },
        {
          title: 'Separado',
          value: 3,
        },
        {
          title: 'Divorciado',
          value: 4,
        },
        {
          title: 'Viúvo',
          value: 5,
        },
      ],
      listaSexo: [
        {
          title: 'Masculino',
          value: 1,
        },
        {
          title: 'Feminino',
          value: 2,
        },
        {
          title: 'Outro',
          value: 3,
        },
      ],
      listaUf: [],
      nacionalidade: 'Brasileiro Nato',
      config: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      mask: '###.####.###-##',
      optionsLocal: {
        numberCpfCnpj: null,
        numberRg: null,
        expeditedOrgan: null,
        sexo: null,
        civilState: null,
        birthDate: null,
        nationality: null,
        politicallyExposedPerson: null,
        ufExpeditedOrgan: null,
        userId: localStorage.getItem('id'),
        phoneNumber: null,
        emailAddress: null,
        typePerson: null,
        id: null,
      },
      optionsResponsible: {
        name: null,
        cpf: null,
        rg: null,
        civilState: null,
        nationality: null,
        street: null,
        profession: null,
        streetNumber: null,
        distrct: null,
        city: null,
        postalCode: null,
        phoneNumber: null,
        userId: localStorage.getItem('id'),
        id: null,
      },
      exposta: false,
    }
  },

  async mounted() {
    this.loading = true
    this.isInvestor = localStorage.getItem('role') === 'INVESTOR'
    this.getInvestorDocuments()
    const responseEstates = await StateService.getAll()
    this.listaUf = responseEstates.data.result.items.map(res => ({
      value: res.estate.id,
      code: res.estate.code,
      title: res.estate.name,
      initials: res.estate.initials,
    }))
    const responseUserData = await this.$http.get(
      `/api/services/app/User/GetUserForEdit?Id=${this.dataUser.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    this.dataUser.name = responseUserData.data.result.user.name

    this.optionsLocal.numberCpfCnpj = responseUserData.data.result.user.numberCpfCnpj
    this.optionsLocal.numberRg = responseUserData.data.result.user.numberRg
    this.optionsLocal.expeditedOrgan = responseUserData.data.result.user.expeditedOrgan
    this.optionsLocal.sexo = responseUserData.data.result.user.sexo
    this.optionsLocal.nationality = responseUserData.data.result.user.nationality
    this.optionsLocal.politicallyExposedPerson = responseUserData.data.result.user.politicallyExposedPerson
    this.optionsLocal.ufExpeditedOrgan = responseUserData.data.result.user.ufExpeditedOrgan
    this.optionsLocal.civilState = responseUserData.data.result.user.civilState
    this.optionsLocal.phoneNumber = responseUserData.data.result.user.phoneNumber
    this.optionsLocal.emailAddress = responseUserData.data.result.user.emailAddress
    this.optionsLocal.typePerson = responseUserData.data.result.user.typePerson
    this.disableCpfCnpj = this.optionsLocal.numberCpfCnpj !== null
    this.optionsLocal.birthDate = this.frontEndDateFormat(
      responseUserData.data.result.user.birthDate,
    )
    this.optionsLocal.userId = localStorage.getItem('id')
    this.optionsLocal.id = responseUserData.data.result.user.id

    const responsible = (await UserResponsibleService.GetResponsibleDataByUserId(this.optionsLocal.userId)).data.result.responsibleData
    if(responsible !== null) {
      this.optionsResponsible = responsible
      this.optionsResponsible.userId = localStorage.getItem('id')

    }
    this.loading = false
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.personalRules.validate().then(success => {
          if (success) {
            this.alterData()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async alterData() {
      try {
        this.loading = true
        const responseUser = await this.$http.get(
          `/api/services/app/User/GetUserForEdit?Id=${this.dataUser.id}`,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )

        const {numberCpfCnpj} = this.optionsLocal
        let numberRg = this.optionsLocal.numberRg
        const {birthDate} = this.optionsLocal
        if(numberRg === null){
          numberRg = '0'          
          this.optionsLocal.expeditedOrgan = 'null'
          this.optionsLocal.ufExpeditedOrgan = this.optionsResponsible.estateId
        }
        const alterUser = {
          user: {
            id: this.dataUser.id,
            name: this.dataUser.name,
            emailAddress: responseUser.data.result.user.emailAddress,
            surname: responseUser.data.result.user.surname,
            userName: responseUser.data.result.user.userName,
            typePerson: responseUser.data.result.user.typePerson,
            phoneNumber: this.optionsLocal.phoneNumber,
            numberCpfCnpj: numberCpfCnpj.replace(/[^\d]+/g, ''),
            numberRg: numberRg.replace(/[^\d]+/g, ''),
            termsOfUse: true,
            isActive: true,
            shouldChangePasswordOnNextLogin: false,
            isTwoFactorEnabled: false,
            isLockoutEnabled: false,
            sexo: this.optionsLocal.sexo,
            birthDate: birthDate,
            answerQuizLater: false,

            expeditedOrgan: this.optionsLocal.expeditedOrgan,
            nationality: this.optionsLocal.nationality,
            politicallyExposedPerson: this.optionsLocal.politicallyExposedPerson,
            ufExpeditedOrgan: this.optionsLocal.ufExpeditedOrgan,
            civilState: this.optionsLocal.civilState,
          },
        }

        await this.$http.post(
          '/api/services/app/User/CreateOrUpdateUser',
          alterUser,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        if(this.optionsLocal.typePerson === 'J') {
          await this.UpdateResponsible()
        }

        this.createDocs()

        if (responseUser) {
          this.msgSuccess()
          this.$emit('save')
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        this.msgError('Erro ao gravar dados')
      }
    },

    async UpdateResponsible() {
      const responsible = {
        id: this.optionsResponsible.id,
        postalCode: this.optionsResponsible.postalCode,
        civilState: this.optionsResponsible.civilState,
        cpf: this.optionsResponsible.cpf,
        district: this.optionsResponsible.district,
        name: this.optionsResponsible.name,
        nationality: this.optionsResponsible.nationality,
        phoneNumber: this.optionsResponsible.phoneNumber,
        profession: this.optionsResponsible.profession,
        rg: this.optionsResponsible.rg,
        estateId: this.optionsResponsible.estateId,
        city: this.optionsResponsible.city,
        street: this.optionsResponsible.street,
        streetNumber: this.optionsResponsible.streetNumber,
        birthDate: this.optionsResponsible.birthDate,
        userId: new Number(this.optionsResponsible.userId)
      }
      this.optionsResponsible.id = (await UserResponsibleService.CreateOrEdit(responsible)).data.result
    },

    formatRg(v) {
      let r = null
      if (v.length === 9) {
        r = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')
      }
      return r
    },
    frontEndDateFormat(v) {
      return Moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },

    async validarCpf($event){
      const result  = (await ValidationCpfCnpjService.validaCpf($event)).data.result
      if(result === false) {
        this.optionsLocal.numberCpfCnpj = null
        this.msgError('CPF inválido')
      }
    },
    async validarCpfRepresentante($event){
      const result  = (await ValidationCpfCnpjService.validaCpf($event)).data.result
      if(result === false) {
        this.optionsResponsible.cpf = null
        this.msgError('CPF inválido')
      }
    },

    async validarCnpj($event){
      const result  = (await ValidationCpfCnpjService.validaCnpj($event)).data.result
      if(result === false) {
        this.optionsLocal.numberCpfCnpj = null
        this.msgError('CNPJ inválido')
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    async getInvestorDocuments() {
      if(localStorage.getItem('role') === 'INVESTOR') {
        try {
          const { result } = (await InvestorDocumentsService.getInvestorDocuments()).data
          if(result.investorDocuments !== null) {
            this.documentFile[0] = await this.getBinaryObjects(result.investorDocuments.documentFiletId)
            this.selfieDocumentFile[0] = await this.getBinaryObjects(result.investorDocuments.selfieDocumentFiletId)
            this.documentsId = result.investorDocuments.id
          }
        } catch (e) {
          if (!this.msgIsOpen()) this.msgError('Erro ao obter os documentos do investidor')
        }
      }
    },    

    changeFile($event, list) {
      const reader = new FileReader()
      reader.onload = e => {
        const arr = {
          bytes: e.target.result.split(',')[1],
          description: $event.target.files[0].name,
          dataType: e.target.result.split(',')[0],
        }
        list[0] = arr
        return arr
      }
      reader.readAsDataURL($event.target.files[0])
    },

    async getBinaryObjects(id) {
      const r = await BinaryObjectsService.getAppBinaryObjectsForEdit(id)
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })
      // // console.log(r.data.result.appBinaryObjects)
      return r.data.result.appBinaryObjects
    },

    createDocs() {
      const docs = {
        documentFile: this.documentFile[0],
        selfieDocumentFile: this.selfieDocumentFile[0],
        id: this.documentsId
      }
      InvestorDocumentsService.CreateOrEdit(docs)
        .then(d => this.getInvestorDocuments())
        .catch()
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.upload-documents-modal{
  display: flex;
}
.upload-doc-card-settings {
    margin-left: 9em;
}
.upload-selfie-card-settings{
  margin-left: 5.5em;
}
.botao-salvar-dados-pessoais {
  float: right;
}
@media (max-width: 1000px) {
  .upload-documents-modal{
    display: contents;
  }
  .upload-doc-card-settings {
    margin-left: 0% !important;
    max-width: 70% !important;
  }
  .upload-selfie-card-settings{
    margin-left: 0% !important;
    max-width: 70% !important;
  }
  .botao-salvar-dados-pessoais {
    float: none;
  }
}


</style>
