import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================

  baseURL:
  // eslint-disable-next-line no-nested-ternary
  process.env.VUE_APP_MODE === 'prd'
    ? 'https://api.divvy.com.br'
    // eslint-disable-next-line no-nested-ternary
    : process.env.VUE_APP_MODE === 'dev'
      ? 'https://api.divvy.com.br'
      : 'https://api.divvy.com.br',
  timeout: 30000,
  // headers: { 'X-Custom-Header': 'foobar' },
})

// ? 'https://divvyhmlapi.azurewebsites.net'
// : 'https://localhost:44301',

Vue.prototype.$http = axiosIns
Vue.prototype.isDev = process.env.VUE_APP_MODE !== 'prd'
Vue.prototype.fbId = process.env.VUE_APP_MODE === 'prd' ? '236945485315074' : '739262630843778'

Vue.prototype.gapiKey = process.env.VUE_APP_MODE === 'prd' ? 'AIzaSyBjTAOChEAcdcFEp3rbr4mUR9RZawkxlXM' : 'AIzaSyBC1N3K6HHZWsRrJPWDhzK-g9ayQZ_2U-A'

Vue.prototype.gapiClientId = process.env.VUE_APP_MODE === 'prd' ? '405961673942-14heqturqv3r7l1dhdfrfql8r2rpd209.apps.googleusercontent.com' : '262669626393-el542q1n6jmqr0qsdqpk6l2gvt0jinfh.apps.googleusercontent.com'

Vue.prototype.twitterKey = process.env.VUE_APP_MODE === 'prd' ? 'mtiw22xepokQmG3IFpBFDYbS7' : 'vwvcpd2wBBRhW0rFFyAt4ew9M'

Vue.prototype.twitterSecret = process.env.VUE_APP_MODE === 'prd' ? 'D7kOGADRVO7Uuzz1VrSZTFGd3pz1qnhHtCLSl5tNpuksWtv2tD' : 'UQcEf90cbA1uwtKEToiYQ9KToFmRYDmgo77eFAFFY6l5mKK7iH'

export default axiosIns
