import Vue from 'vue'

const PaymentService = {

  createBoletoPayment(investmentId) {
    return Vue.prototype.$http.post(`/api/services/app/QuotaPayments/CreateBoletoPayment?investmentId=${investmentId}`)
  },

  createTedPayment(investmentId) {
    return Vue.prototype.$http.post(`/api/services/app/QuotaPayments/CreateTedPayment?investmentId=${investmentId}`)
  },

  getQuotaPaymentByInvestment(investmentId) {
    return Vue.prototype.$http.get(`/api/services/app/QuotaPayments/GetQuotaPaymentByInvestment?investmentId=${investmentId}`)
  },

  reenviarBoleto(investmentId) {
    return Vue.prototype.$http.post(`/api/services/app/QuotaPayments/ReenviarBoleto?investmentId=${investmentId}`)
  },
}

export default PaymentService
