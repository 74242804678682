import Vue from 'vue'

const ClicksignInvestmentService = {
    getEstatuto(investmentId) {
        return Vue.prototype.$http.get(`/api/services/app/InvestmentDocumentRequests/GetEstatuto?investmentId=${investmentId}`)
    },
    getPromessa(investmentId) {
        return Vue.prototype.$http.get(`/api/services/app/InvestmentDocumentRequests/GetPromessa?investmentId=${investmentId}`)
    },
}
export default ClicksignInvestmentService
