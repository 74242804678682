import Vue from 'vue'

const BinaryObjectsService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/AppBinaryObjects/CreateOrEdit', item)
  },

  getAppBinaryObjectsForEdit(id) {
    return Vue.prototype.$http.get(`api/services/app/AppBinaryObjects/GetAppBinaryObjectsForEdit?Id=${id}`)
  },
}

export default BinaryObjectsService
