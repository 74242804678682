import Vue from 'vue'

const InvestorDocumentsService = {

  CreateOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/InvestorDocumentses/CreateOrEdit', item)
  },

  UpdateSituation(item) {
    return Vue.prototype.$http.put('/api/services/app/InvestorDocumentses/UpdateSituation', item)
  },

  getInvestorDocuments(userId = undefined) {
    const userIdFilter = userId ? userId : ''
    return Vue.prototype.$http.get('/api/services/app/InvestorDocumentses/GetInvestorDocumentsByUser?userId='+userIdFilter)
  },
}

export default InvestorDocumentsService
