export default {
  PENDENTE_PAGAMENTO: 1,
  PENDENTE_ASSINATURA: 2,
  PAGO: 3,
  CANCELADO: 4,

  listStatusCota: [
    {
      text: '',
      value: undefined,
    },
    {
        text: 'Em Pagamento',
        value: 1,
    },
    {
        text: 'Em Assinatura',
        value: 2,
    },
    {
        text: 'Pago',
        value: 3,
    },
    {
        text: 'Cancelado',
        value: 4,
    }
  ],
  listStatusComissao: [
      {
        text: '',
        value: undefined,
      },
      {
        text: 'Pago',
        value: 1,
      },
      {
          text: 'Pendente',
          value: 2,
      }
  ]

}
