<template>
  <div class="fadeIn01s">
    <loader v-if="loading"></loader>
        <br>
        <br>
          <br>
          <div class="detalhes-ted">
            <h4><b>Utilize os dados bancarios abaixo para realizar o TED</b></h4>
            <h4><div>Banco Bradesco SA</div></h4>
            <h4><div>CNPJ 41.789.953/0001-68</div></h4>
            <h4><div>Agência: 0333</div></h4>
            <h4><div>CC: 0026600 – 0</div></h4>
          </div>
          <br>
        <div>
          <b>Importante</b>
          <ul>
            <li>A Divvy não envia cobranças via SMS, e-mail ou outros meios de comunicação que não seja o próprio portal</li>
            <li>Transação sujeita à cobrança de tarifa. Consulte os valores na tabela de serviços do seu banco</li>
            <li>Seu pedido será confirmado em até 72h</li>
          </ul>
    </div>
  </div>
</template>

<script>
import Loader from '../../../components/Loader.vue'
import InvestmentService from '../../../services/Investment/InvestmentService'

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    }
  },

  props: ['investmentId'],

  methods: {
    async createBoleto() {
      this.loading = true
      await InvestmentService.createBoletoPayment(this.investmentId)
        .then(res => console.log(res))
        .catch(error => console.log(error))
    },
  },
}
</script>
<style scoped>
  .detalhes-ted{
    margin-left: 20%;
  }
  @media (max-width: 1000px) {
    .detalhes-ted {
      margin-left: 0 !important;
    }
  }
</style>