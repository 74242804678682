<template>
    <VueHtml2pdf
      style="display: table-column-group;"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="boletoDivvy"
      :pdf-quality="5"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="750px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf">
      <section slot="pdf-content">
        <div v-html="html" style="margin-left: 2em;" ></div>
      </section>
    </VueHtml2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueHtml2pdf,
  },
  props: ['html'],
  emits: ['onProgress'],
  methods: {
    generatePdf() {
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress($event) {
      this.$emit('onProgress', $event)
    },

    hasStartedGeneration() {
    },
    hasGenerated($event) {
    },
  },
}
</script>
