import Vue from 'vue'

const UserService = {

  GetUserForEdit(userId) {
    const id = userId ? `?Id=${userId}` : ''
    return Vue.prototype.$http.get(`/api/services/app/User/GetUserForEdit${id}`,
      this.reset, {
        timeout: 300000,
      })
  },

  GetUserAddressForUserId(userId) {
    return Vue.prototype.$http.get(`/api/services/app/UserAddresses/GetUserAddressForUserId?UserId=${userId}`,
      this.reset, { timeout: 300000 })
  },
}

export default UserService
