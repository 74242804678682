<template>
  <div class="fadeIn01s">
    <Loader v-if="loading"></Loader>
        <br>
        <div class="detalhes-boleto">
          <div>
            <feather-icon icon="PrinterIcon" size="50"/> imprima o boleto e pague no banco
          </div>
          <br>
          <div>
            <feather-icon icon="PrinterIcon" size="50"/> <b>ou pague pela internet</b> utilizando o código de barras do boleto
          </div>
          <br>
          <div>
            <feather-icon icon="CalendarIcon" size="50"/> o prazo de validade do boleto é 1 dia útil
          </div>
        </div>
        <br>
        <div>
          <b>Importante</b>
          <ul>
            <li>No período de 30 dias, se houver cinco ou mais boletos bancários de um mesmo cliente, não pagos, poderá ocorrer bloqueio temporário desta forma de quitação, por até 30 dias. Nesse período, novas compras poderão ser feitas no site por meio de outras formas de pagamento;</li>
            <li>Caso o seu computador tenh um programa anti pop-up, será preciso desativá-lo antes de finalizar sua compra e imprimir o boleto ou pagar pelo Internet Banking;</li>
            <li>Não faça depósito ou transferência entre contas. O boleto não é enviado pelos correios. Imprima-o e pague-o no banco ou pela Internet;</li>
            <li>Se o boleto não for pago até a data de vencimento, o pedido será automaticamente cancelado;</li>
            <li>O prazo para liberação das cotas pagas com boleto bancário começa a contar três dias depois do pagamento do boleto, tempo necessário para que a instituição bancária confirme o pagamento</li>
          </ul>
    </div>
  </div>
</template>

<script>
import Loader from '../../../components/Loader.vue'
import InvestmentService from '../../../services/Investment/InvestmentService'

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    }
  },

  props: ['investmentId'],

  methods: {
    async createBoleto() {
      this.loading = true
      await InvestmentService.createBoletoPayment(this.investmentId)
        .then(res => res)
        .catch(error => error)
    },
  },
}
</script>
<style scoped>
  .detalhes-boleto {
    margin-left: 8em;
  }
  @media (max-width: 1000px) {
    .detalhes-boleto {
      margin-left: 1em;
    }
  }
</style>
