import Vue from 'vue'

const OpportunityService = {
  // BINARY

  getOpenedOpportunities(filter) {
    let url = '/api/services/app/OpportunityService/GetOpenedOpportunities?1=1'

    if (filter !== null) {
      if (filter.city !== undefined && filter.city !== '') {
        url += `&city=${filter.city}`
      }

      if (filter.ofDate !== undefined && filter.ofDate !== null) {
        url += `&ofDate=${filter.ofDate}`
      }

      if (filter.untilDate !== undefined && filter.untilDate !== null) {
        url += `&untilDate=${filter.untilDate}`
      }
      if (filter.ofValue !== undefined && filter.ofValue !== null && filter.ofValue !== '0,00') {
        url += `&ofValue=${filter.ofValue.replace('.', '').replace(',', '.')}`
      }

      if (filter.untilValue !== undefined && filter.untilValue !== null && filter.untilValue !== '0,00') {
        url += `&untilValue=${filter.untilValue.replace('.', '').replace(',', '.')}`
      }

      if (filter.constructionCompanyId !== undefined && filter.constructionCompanyId !== null) {
        url += `&constructionCompanyId=${filter.constructionCompanyId}`
      }

      if (filter.Sorting !== undefined) {
        url += `&sorting=${filter.sorting}`
      }

      if (filter.skipCount !== undefined) {
        url += `&skipCount=${filter.skipCount}`
      }

      if (filter.maxResultCount !== undefined) {
        url += `&maxResultCount=${filter.maxResultCount}`
      }
    }

    return Vue.prototype.$http.get(url,
      this.reset, {
        timeout: 300000,
      })
  },

  getClosedOpportunities(filter) {
    let url = '/api/services/app/OpportunityService/GetClosedOpportunities?1=1'

    if (filter !== null) {
      if (filter.city !== undefined) {
        url += `&city=${filter.city}`
      }

      if (filter.ofDate !== undefined) {
        url += `&ofDate=${filter.ofDate}`
      }

      if (filter.utilDate !== undefined) {
        url += `&utilDate=${filter.utilDate}`
      }

      if (filter.constructionCompanyId !== undefined) {
        url += `&constructionCompanyId=${filter.constructionCompanyId}`
      }

      if (filter.Sorting !== undefined) {
        url += `&sorting=${filter.sorting}`
      }

      if (filter.skipCount !== undefined) {
        url += `&skipCount=${filter.skipCount}`
      }

      if (filter.maxResultCount !== undefined) {
        url += `&maxResultCount=${filter.maxResultCount}`
      }
    }

    return Vue.prototype.$http.get(url,
      this.reset, {
        timeout: 300000,
      })
  },

  getConstructionCompanies() {
    return Vue.prototype.$http.get('/api/services/app/OpportunityService/GetConstructionCompanies',
      this.reset, {
        timeout: 300000,
      })
  },

  getResume(immobileId) {
    return Vue.prototype.$http.get(`/api/services/app/OpportunityService/GetResume?immobileId=${immobileId}`,
      this.reset, {
        timeout: 300000,
      })
  },

  getImmobilesBroker() {
    return Vue.prototype.$http.get(`/api/services/app/OpportunityService/getImmobilesBroker`,
      this.reset, {
        timeout: 300000,
      })
  },

  getCities() {
    return Vue.prototype.$http.get('/api/services/app/OpportunityService/GetCities',
      this.reset, {
        timeout: 300000,
      })
  },

  getDetails(id) {
    return Vue.prototype.$http.get(`/api/services/app/OpportunityService/GetDetails?immobileId=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },

}

export default OpportunityService
