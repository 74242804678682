import Vue from 'vue'

const TermoService = {
  getTermoForEdit() {
    return Vue.prototype.$http.get('/api/services/app/Termos/GetTermoForEdit',
      this.reset, {
        timeout: 300000,
      })
  },
}

export default TermoService
