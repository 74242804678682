<template>

        <b-card
          img-alt="Card image cap"
          style="max-width: 15rem;"
          border-variant="light"
          class="img-size mt-3"
          no-body>
          <b-card-body  style="text-align: -webkit-center;" class="title-upload">
            <b-card-title class="ft-size">{{textCard ? textCard : 'Adicionar Fotos JPEG ou PNG somente'}}</b-card-title>
          </b-card-body>

          <b-link
                  :id="'link'+id"
                  v-if="value && value.id"
                  class="detailing__cursor"
                  :to="{ name: 'company-view-file' }"
                  target="_blank"
                  @click="setDataFile({id: value.id, data: value})">

              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
          </b-link>
          <b-img id="link-cnpj-disabled" v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />

          <b-card-body>
            <b-card-text>
              <b-form-file
                :id="id"
                ref="{{id}}"
                placeholder="Enviar"
                size="sm"
                accept=".jpg, .png, .jpeg, .pdf"
                @change="changeFile" />

            </b-card-text>
          </b-card-body>
        </b-card>
</template>
<script>

import {
  BCard,
  BCardBody,
  BCardText,
  BFormFile,
  BCardTitle,
  BImg,
  BLink,
} from 'bootstrap-vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BFormFile,
    BImg,
    BLink,
  },
  mixins: [toast],
  props: [
    'value',
    'id',
    'textCard',
  ],
  emits: [
    'changeFile',
    'changeFileGetModel',
  ],
  data() {
    return {
      address: {},
    }
  },
  methods: {
    async changeFile($event) {
      this.$emit('changeFile', $event)
      this.$emit('changeFileGetBinary', $event)
    },

    getBinaryFile(event) {
      const reader = new FileReader()
      reader.onload = e => {
        const arr = {
          bytes: e.target.result.split(',')[1],
          description: event.target.files[0].name,
          dataType: e.target.result.split(',')[0],
        }
        console.log(`getBinaryFile`)
        return arr
      }
    },

    setDataFile(data) {

      localStorage.setItem('fileDataView', JSON.stringify(data))
    },
  },
}
</script>
<style lang="scss">
@import '@/views/sass/variables.scss';
@import '@/views/sass/company';
  .inpf {
    height: 100px;
    width: 100px;
  }
  .imgt {
    height: 150px;
    width: 150px;
  }
  .drag_position {
    display: inline-block;
    max-width: 180px;
  }
  .drag_primary {
    padding: 0;
    width: 100%;
  }
  .remove-img{
    padding: 0px !important;
    width: 26px;
    height: 26px;
    margin-left: 125px;
    position: relative;
    margin-bottom: -26px;
    display: block;
    z-index: 100;
  }
  div.img-size > img,
  div.img-size > a img {
    padding: 10px 35px
  }
  .title-upload {
    border-bottom: 1px solid #e5e5e5;
    padding: 22px 0 0 0;
    margin: 0 0 15px 0;
    background-color: #e3e3e3;
  }
  .ft-size {
    font-size: 12px !important;
  }
  .border-light {
    border: 1px solid #e5e5e5 !important;
  }
  .custon-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: var(--color) !important;
  }
  .nav-pills .nav-link.active {
    box-shadow: 0 4px 18px -4px var(--color) !important;
  }
  .btn-primary,
  .btn-primary:focus {
    border-color: var(--color) !important;
    background-color: var(--color) !important;
  }
  .btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 4px 18px -4px var(--color) !important;
  }
  div.company-nav-link a.active {
    color: #fff !important;
  }
.custom-file-input:focus ~ .custom-file-label {
    border-color: var(--color) !important;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
</style>
