<template>
    <div class="card-all">
      <Loader v-if="loading" />
      <div class="card-principal-dados-pessoais ">
        <div v-if="userData" class="dados-pessoais fadeInFast">
          <div>
            <b class="title-card">Dados Pessoais</b>
            <b style="float: right; font-size: 1.2em; color: #f8ac18;" @click="$bvModal.show('modal-dados-pessoais')"><a>Editar</a></b>
          </div>
          <br>
          <div>{{ userData.name }}</div>
          <div>{{ userData.numberCpfCnpj }}</div>
          <div>{{ userData.emailAddress }}</div>
          <div>{{ userData.phoneNumber }}</div>
        </div>
        <div v-if="bank" class="dados-bancarios fadeInFast">
          <div>
            <b class="title-card">Dados Bancarios</b>
            <b style="float: right; font-size: 1.2em; color: #f8ac18;" @click="edit"><a>Editar</a></b>
          </div>
          <br>
          <div class="fadeInFast" v-if="bank.userBankData">
            <div>{{bank.bankName}}</div>
            <div>{{bank.userBankData.agency}}</div>
            <div>{{bank.userBankData.checkingAccount}}-{{bank.userBankData.checkingAccountDigit}}</div>
          </div>
          <div v-else>Nenhum banco cadastrado</div>
        </div>
      </div>
      <br>
      <div class="dados-endereco fadeInFast">
        <div>
          <b class="title-card">Endereço Antigo </b>
          <b style="float: right; font-size: 1.2em; color: #f8ac18" @click="$bvModal.show('modal-dados-endereco')"><a>Editar</a></b>
        </div>
        <br>
        <div v-if="address && address.userAddress">
          <div>{{address.userAddress.logradouro}}, {{address.userAddress.number}}, {{address.userAddress.district}}</div>
          <div v-if="address.userAddress.complement">{{address.userAddress.complement}}</div>
          <div>{{address.userAddress.city}}/{{address.estateName}}</div>
          <div>{{address.userAddress.zipCode}}</div>
        </div>
        <div v-else>
          Nenhum endereço cadastrado
        </div>
      </div>
      <br>
      <div v-if="!hasDocuments" class="documentos fadeIn">
        <div>
          <b class="title-card">Documentação </b>
              <b style="float:left;font-size: 1.2em;color: #f8ac18;margin-top: 5px;" />
        </div>
       <br>
          <ul>
            <li>Adicione uma selfie com seu documento (RG, CPF)</li>
            <li>Adicione uma foto frente e verso do seu documento (RG, CPF)</li>
          </ul>

        <div class="upload-documents-modal">
          <div>
            <b-row class="custom-center">
              <ImgUpload :id="'uploadDoc'" class="upload-doc-card" textCard="Foto do documento (RG, CPF)" @changeFileGetBinary="changeFile($event, documentFile)"/>
            </b-row>
          </div>
          <div>
            <b-row class="custom-center">
              <ImgUpload :id="'uploadSelfie'" class="upload-selfie-card" textCard="Selfie com o documento (RG, CPF)" @changeFileGetBinary="changeFile($event, selfieDocumentFile)"/>
            </b-row>
          </div>
        </div>
      </div>
<br></br>
      <b-modal class="modal-personal" size="lg" id="modal-dados-pessoais" hide-footer>
        <div class="d-block text-center">
          <AccountSettingPersonalData @save="getUserData" :isModal="true"></AccountSettingPersonalData>
        </div>
      </b-modal>
      <b-modal id="modal-dados-bancarios" size="lg" hide-footer>
        <div class="d-block text-center">
          <AccountSettingBankData :isCheckoutCota="true" @save="getUserBank"></AccountSettingBankData>
        </div>
      </b-modal>
      <b-modal style="width: 70em !important" size="lg"  id="modal-dados-endereco" hide-footer>
        <div class="d-block text-center">
          <AccountSettingAddressData @save="getUserAddress"></AccountSettingAddressData>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { BCard, BRow, BModal } from 'bootstrap-vue'
import toast from '@/mixins/toast'
import Ripple from 'vue-ripple-directive'
import UserService from '../../services/User/UserService'
import UserBankService from '../../services/User/UserBankService'
import InvestorDocumentsService from '../../services/Investor/InvestorDocumentsService'
import AccountSettingPersonalData from '../account-setting/AccountSettingPersonalData.vue'
import AccountSettingBankData from '../account-setting/AccountSettingBankData.vue'
import AccountSettingAddressData from '../account-setting/AccountSettingAddressData.vue'
import ImgUpload from '../img/ImgUpload.vue'
import Loader from '../../components/Loader.vue'
import UserResponsibleService from '@/services/User/UserResponsibleService'

// import ImgUploadView from '../img/ImgUploadView.vue'

export default {
  components: {
    BCard,
    BRow,
    Loader,
    BModal,
    ImgUpload,
    // ImgUploadView,
    AccountSettingPersonalData,
    AccountSettingBankData,
    AccountSettingAddressData,
  },
  data() {
    return {
      documentFile: [],
      selfieDocumentFile: [],
      userData: null,
      userResponsible: null,
      bank: null,
      address: null,
      hasDocuments: false,
      loading: false
    }
  },
  created() {
    this.hasDocuments = false
  },
  props: ['userId'],
  mixins: [toast],

  directives: {
    Ripple,
  },

  methods: {
    async getUserData() {
      try {
        this.loading = true
        this.$bvModal.hide('modal-dados-pessoais')
        this.userData = (await UserService.GetUserForEdit()).data.result.user
        this.userResponsible = (await UserResponsibleService.GetResponsibleDataByUserId(localStorage.id)).data.result.responsibleData
        this.loading = false
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter dados do usuário')
      }
    },

    async getUserBank() {
      try {
        this.$bvModal.hide('modal-dados-bancarios')
        this.bank = (await UserBankService.GetUserBankDataForUserId(localStorage.id)).data.result
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter o banco do usuário')
      }
    },

    async getUserAddress() {
      try {
        this.$bvModal.hide('modal-dados-endereco')
        this.address = (await UserService.GetUserAddressForUserId(localStorage.id)).data.result
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter o endereço do usuário')
      }
    },

    async getInvestorDocuments() {
      try {
        const { result } = (await InvestorDocumentsService.getInvestorDocuments()).data
        this.hasDocuments = result.investorDocuments !== null
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Erro ao obter os documentos do investidor')
      }
    },

    removeDocFile() {
      this.documentFile = []
    },

    removeSelfieFile() {
      this.selfieDocumentFile = []
    },

    changeFile($event, list) {
      const reader = new FileReader()
      reader.onload = e => {
        const arr = {
          bytes: e.target.result.split(',')[1],
          description: $event.target.files[0].name,
          dataType: e.target.result.split(',')[0],
        }
        list.push(arr)
        return arr
      }
      reader.readAsDataURL($event.target.files[0])
    },

    edit() {
      this.$bvModal.show('modal-dados-bancarios')
      var elemento = document.getElementById('modal-dados-bancarios')
    },

    validate() {
      const { bank } = this
      const { address } = this
      if (bank === undefined || bank.userBankData === null) {
        this.msgError('Preencha seus dados bancários')
        return false
      }
      if (address === undefined || address.userAddress === null) {
        this.msgError('Preencha seu endereço')
        return false
      }
      if (!this.hasDocuments) {
        if (this.documentFile.length > 0 && this.selfieDocumentFile.length > 0) {
          this.createDocs()
        } else {
          this.msgError('Anexe os documentos solicitados')
          return false
        }
      }
      if(this.userData.numberCpfCnpj === null || (this.userData.typePerson === 'J' && this.userResponsible === null)) {        
        this.msgError('Dados pessoais incompletos')
        return false
      }
      return true
    },

    createDocs() {
      const docs = {
        documentFile: this.documentFile[0],
        selfieDocumentFile: this.selfieDocumentFile[0],
      }
      InvestorDocumentsService.CreateOrEdit(docs)
        .then()
        .catch()
    },
  },

  mounted() {
    this.hasDocuments = false
    this.getUserData()
    this.getUserBank()
    this.getUserAddress()
    this.getInvestorDocuments()
  },
}
</script>
<style lang="scss" scoped>
  .modal-personal {
    background-color: aqua !important;
  }
  .card-all {
    width: 100em; 
    margin-top: 2.7%;
    margin-left: 2em;
  }
  .dados-pessoais {
    position: relative;
    width: 48%;
    height: 20%;
    padding: 2em;
    border: 1px solid #D3D8E3;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 2;
    background: white;
  }
  .dados-bancarios {
    position: relative;
    width: 48%;
    margin-left: 5.5%;
    padding: 2em;
    border: 1px solid #D3D8E3;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 4 !important;
    background: white;
  }
  .dados-endereco {
    position: relative;
    width: 100%;
    height: 217px;
    margin-top: 2.5%;
    padding: 2em;
    border: 1px solid #D3D8E3;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .documentos {
    position: relative;
    width: 100%;
    height: 800px;
    margin-top: 2.5%;
    padding: 2em;
    border: 1px dashed #D3D8E3;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .title-card {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }

  .card-principal-dados-pessoais {
    display: flex;
  }

  .upload-documents{
    display: flex;
  }
  .custom-center {
  align-items: center;
  justify-content: center;
}

div.modal-dados-bancarios___BV_modal_outer_ div {
    background: black !important;
}

div#modal-dados-pessoais___BV_modal_content_ {
  ::v-deep .modal .modal-content{
    width: 200% !important;
  }
}
  .upload-doc-card {
    margin-left: 9em;
  }
  .upload-selfie-card{
    margin-left: 5.5em;
  }

@media (max-width: 1000px) {
  .documentos {
    width: 100%;
    height: 780px;
  }
  .card-principal-dados-pessoais {
    display: flow-root;
  }
  .card-all{
    width: 100% !important;
    margin-left: 0 !important;
  }
  .dados-pessoais{
    width: 100% !important;
  }
  .dados-bancarios{
    width: 100% !important;
    margin-top: 8%;
    margin-left: 0% !important;
  }

  .upload-doc-card {
    margin-left: 0;
  }
  .upload-selfie-card{
    margin-left: 0;
  }

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 1000px) {
  .upload-documents-modal{
    display: contents;
  }
  .upload-doc-card-settings {
    margin-left: 0% !important;
    max-width: 80% !important;
  }
  .upload-selfie-card-settings{
    margin-left: 0% !important;
    max-width: 80% !important;
  }
  .botao-salvar-dados-pessoais {
    float: none;
  }
}

}

</style>
