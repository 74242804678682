import Vue from 'vue'

const InvestmentService = {

  CreateOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/InvestmentQuotas/CreateOrEdit', item, { timeout: 300000 })
  },
  
  getAll(filter) {
    return Vue.prototype.$http.get(`/api/services/app/InvestmentQuotas/GetAll?${filter}`)
  },

  getAllByInvestor(filter) {
    return Vue.prototype.$http.get(`/api/services/app/InvestmentQuotas/GetAllByInvestor?${filter}`)
  },

  getAllByInvestorPending(filter) {
    return Vue.prototype.$http.get(`/api/services/app/InvestmentQuotas/GetAllByInvestorPending?${filter}`)
  },

  getImmobilesByInvestor() {
    return Vue.prototype.$http.get('/api/services/app/InvestmentQuotas/GetImmobilesByInvestor')
  },
  
  updateSituationToPaymentPendent(investmentId) {
    return Vue.prototype.$http.put(`/api/services/app/InvestmentQuotas/UpdateSituationToPaymentPendent?investmentId=${investmentId}`)
  },
  
  getResumeDashboard() {
    return Vue.prototype.$http.get('/api/services/app/InvestmentQuotas/GetResumeDashboard')
  },

  getInvestmentQuota(investmentId) {
    return Vue.prototype.$http.get(`/api/services/app/InvestmentQuotas/GetInvestmentQuotaForEdit?Id=${investmentId}`)
  },

  getQuotaDetails(immobileId) {
    return Vue.prototype.$http.get(`/api/services/app/InvestmentQuotas/GetQuotaDetails?immobileId=${immobileId}`)
  },
}

export default InvestmentService
