import Vue from 'vue'

const BrokerInvestmentService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/InvestmentAssociatedBrokers/CreateOrEdit', item)
  },

  getAssociatedBrokerByInvestment(investmentId) {
    return Vue.prototype.$http.get(`/api/services/app/InvestmentAssociatedBrokers/GetAssociatedBrokerByInvestment?investmentId=${investmentId}`)
  },

  
  getDashboardBroker() {
    return Vue.prototype.$http.get(`/api/services/app/InvestmentAssociatedBrokers/getDashboardBroker`)
  },
}

export default BrokerInvestmentService
